import React from 'react';

const PersonIcon = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.24605 35.1625C4.67464 31.0909 8.10803 28 12.2021 28H35.7979C39.892 28 43.3254 31.0909 43.7539 35.1625L45 47H3L4.24605 35.1625Z"
        fill="#F0F0F0"
      />
      <circle cx="24" cy="14" r="12" fill="#F0F0F0" />
    </svg>
  );
};
export default PersonIcon;
