import React from 'react';
import { categoryBgColors } from '../vars/categoryColors';
import { centerTitle, filterButton } from './FilterButton.module.scss';
import TranslatedText from './TranslatedText';

const FilterButton = ({
  category,
  icon,
  activeCategory,
  setActiveCategory,
  textData,
  index,
}) => {
  return (
    <button
      type="button"
      className={`${filterButton} buttonTextFilter ${
        category === activeCategory ? categoryBgColors[category] : ''
      }`}
      onClick={() =>
        setActiveCategory(activeCategory !== category ? category : 'NONE')
      }
    >
      {icon && <span>{icon}</span>}
      <span className={`${!icon ? centerTitle : ''}`}>
        <TranslatedText name={`categories.${index}.title`} data={textData} />
      </span>
    </button>
  );
};
export default FilterButton;
