import React from 'react';

const OrganizationIcon = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5934 17.7917C14.7974 15.8529 16.4324 14.381 18.3819 14.381H29.618C31.5676 14.381 33.2026 15.8529 33.4066 17.7917L34 23.4286H14L14.5934 17.7917Z"
        fill="#F0F0F0"
      />
      <circle cx="24.0004" cy="7.71429" r="5.71429" fill="#F0F0F0" />
      <path
        d="M1.59336 41.3617C1.79745 39.4228 3.43239 37.9509 5.38195 37.9509H16.618C18.5676 37.9509 20.2026 39.4228 20.4066 41.3617L21 46.9985H1L1.59336 41.3617Z"
        fill="#F0F0F0"
      />
      <circle cx="11.0004" cy="31.2842" r="5.71429" fill="#F0F0F0" />
      <path
        d="M27.5934 41.3617C27.7974 39.4228 29.4324 37.9509 31.3819 37.9509H42.618C44.5676 37.9509 46.2026 39.4228 46.4066 41.3617L47 46.9985H27L27.5934 41.3617Z"
        fill="#F0F0F0"
      />
      <circle cx="37.0004" cy="31.2842" r="5.71429" fill="#F0F0F0" />
    </svg>
  );
};
export default OrganizationIcon;
