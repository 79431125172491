import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactPlayer from 'react-player';
import { mediaWrapper } from './HotspotContentMedia.module.scss';

const HotspotContentMedia = ({ media, site, muralInsert, index }) => {
  return (
    <>
      {media.map(({ localFile, ext, alternativeText }, index) => {
        return (
          <div key={index} className={`${mediaWrapper}`}>
            {/* TODO what other extensions should we check for? */}
            {(ext === '.jpg' || ext === '.png') && (
              <GatsbyImage image={getImage(localFile)} alt={alternativeText} />
            )}
            {ext === '.mp4' && (
              <ReactPlayer
                key={index}
                // TODO this .map was refactored a little, check that pathPrefix still works
                // url={`${site.pathPrefix}${localFile.publicURL}`}
                url={`${localFile.publicURL}`}
                width="100%"
                height="100%"
                volume={0}
                loop={true}
                playing={true}
              />
            )}
          </div>
        );
      })}
      {muralInsert && (
        <div key={index} className={`${mediaWrapper}`}>
          <GatsbyImage
            image={getImage(muralInsert.localFile)}
            alt={muralInsert.alternativeText}
          />
        </div>
      )}
    </>
  );
};
export default HotspotContentMedia;
