import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import FilterButton from './FilterButton';
import { filtersContainer } from './Filters.module.scss';
import EventIcon from './icons/EventIcon';
import OrganizationIcon from './icons/OrganizationIcon';
import PersonIcon from './icons/PersonIcon';
import PlaceSiteIcon from './icons/PlaceSiteIcon';
import WarIcon from './icons/WarIcon';

const Filters = ({ pageName, activeCategory, setActiveCategory }) => {
  const { allStrapiOsd } = useStaticQuery(graphql`
    query FiltersQuery {
      allStrapiOsd {
        nodes {
          name
          categories {
            category_id
            title
            drawer_label
          }
          localizations {
            data {
              attributes {
                locale
                categories {
                  category_id
                  title
                  drawer_label
                }
              }
            }
          }
        }
      }
    }
  `);

  const category_to_icon_map = {
    SITE: <PlaceSiteIcon />,
    WAR: <WarIcon />,
    ORGANIZATION: <OrganizationIcon />,
    ALL_MAP: '',
    EVENT: <EventIcon />,
    PERSON: <PersonIcon />,
    PLACE: <PlaceSiteIcon />,
    ALL_MURAL: '',
  };

  return (
    <div className={`${filtersContainer}`}>
      {allStrapiOsd.nodes.map((node) => {
        if (node.name === pageName) {
          return node.categories.map((category, index) => (
            <FilterButton
              category={category.category_id}
              icon={category_to_icon_map[category.category_id]}
              key={category.title}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              textData={node}
              index={index}
            />
          ));
        }

        return <></>;
      })}
    </div>
  );
};
export default Filters;
