import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import TranslatedText from './TranslatedText';

const HotspotContentText = ({ credit, hotspot }) => {
  const touchwall = process.env.GATSBY_CAMDEN_OFFLINE === 'true';
  // Next time let's make sure we don't have to hide characters in addition to the link
  // If the links weren't wrapped in () then we could just add 'a' to ReactMarkdown's disallowedElements prop and not need regex
  const regexLink = / \(\[([^[\]]*)\]\((.*?)\)\)/; // Matching a link enclosed in parenthesis, ex. ([link](https://www.battleshipnewjersey.org))

  const { strapiInterface } = useStaticQuery(graphql`
    query HotspotContentTextQuery {
      strapiInterface {
        photo
        localizations {
          data {
            attributes {
              locale
              photo
            }
          }
        }
      }
    }
  `);

  const creditData = { credit: credit, localizations: hotspot.localizations };

  return (
    <>
      <TranslatedText
        data={hotspot}
        name="body"
        transform={(body) => (touchwall ? body.replace(regexLink, '') : body)}
        preserve_outer_p_tag
      />
      {credit && (
        <>
          <br />
          <span className={`credit`}>
            <TranslatedText
              data={strapiInterface}
              name="photo"
              className={`inline`}
            />
            &nbsp;
            <TranslatedText
              data={creditData}
              name="credit"
              className={`inline`}
            />
          </span>
        </>
      )}
    </>
  );
};
export default HotspotContentText;
