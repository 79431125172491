import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import {} from './HotspotContentThumbnail.module.scss';

const HotspotContentThumbnail = ({ osdId, muralInsert, thumbnail, media }) => {
  return (
    <>
      {/* Thumbnail should be the mural_insert if viewing the mural */}
      {osdId === 'mural' ? (
        muralInsert && (
          <GatsbyImage
            image={getImage(muralInsert.localFile)}
            alt={muralInsert.alternativeText}
          />
        )
      ) : // Prefer the thumbnail image field if not viewing the mural
      thumbnail ? (
        <GatsbyImage
          image={getImage(thumbnail.localFile)}
          alt={thumbnail.alternativeText}
          style={{ height: '100%' }}
        />
      ) : (
        // If there's no thumbnail, use the first image in the media field
        media &&
        media[0] && (
          <GatsbyImage
            image={getImage(media[0].localFile)}
            alt={media[0].alternativeText}
            style={{ height: '100%' }}
          />
        )
      )}
    </>
  );
};
export default HotspotContentThumbnail;
